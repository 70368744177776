import { HStack, Tooltip, Box } from '@gluestack-ui/themed-native-base';

import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useState } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import { useMessageContext, isDate } from '~/lib/StreamChatReact';
import type { MessageTimestampProps } from 'stream-chat-react';

const UnMemoizedMessageTimestamp = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageTimestampProps<StreamChatGenerics>
) => {
  const [isHovered, setIsHovered] = useState(false);
  const { message: propMessage } = props;
  const { message: contextMessage } = useMessageContext<StreamChatGenerics>('MessageTimestamp');
  const { isMyMessage } = useMessageContext<StreamChatGenerics>('MessageStatus');
  const message = propMessage || contextMessage;

  if (!message.created_at) {
    return;
  }

  const createdDateTime = isDate(message.created_at)
    ? format(message.created_at, 'M月d日 HH:mm:ss', { locale: ja })
    : message.created_at;
  const createdTime = isDate(message.created_at)
    ? message.created_at.toLocaleString('ja-JP', {
        hour: '2-digit',
        minute: '2-digit',
      })
    : message.created_at;

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Tooltip
        label={createdDateTime}
        placement="top"
        bg="inverseSurface"
        _text={{ color: 'surface' }}
        borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
      >
        <HStack
          justifyContent={isMyMessage() ? 'flex-end' : 'flex-start'}
          style={{ textDecoration: isHovered ? 'underline #737373' : 'none', cursor: 'pointer' }}
        >
          <time className="str-chat__message-simple-timestamp">{createdTime}</time>
        </HStack>
      </Tooltip>
    </Box>
  );
};

export const CustomMessageTimestamp = React.memo(UnMemoizedMessageTimestamp) as typeof UnMemoizedMessageTimestamp;
