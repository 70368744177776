import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelectPopover } from '~/components/builder/FormSelectPopover';
import { useFetchCowGroupByIdQuery } from '~/packages/u-motion-api/api/cowGroupApi';

export const UmotionPenSelector = ({
  onChange,
  label,
  cowGroupId,
}: {
  onChange: (value: string | number) => void;
  label: string;
  cowGroupId: number;
}) => {
  const cowGroup = useFetchCowGroupByIdQuery(cowGroupId || skipToken);

  const [pensOption, setPensOption] = useState<{ label: string; value: string }[]>([]);

  const { control } = useForm({
    defaultValues: {
      pen: '',
    },
    mode: 'onChange',
  });

  useDidUpdate(
    () => {
      if (cowGroup.isSuccess) {
        setPensOption(cowGroup.data.pens.split(',').map((pen) => ({ label: pen, value: pen })) || []);
      }
    },
    [cowGroup.data, cowGroup.isSuccess],
    true
  );

  return (
    <FormSelectPopover
      control={control}
      name="pen"
      label={label}
      options={pensOption}
      defaultValue={{ pen: '' }}
      wrapperStyle={{ zIndex: 999998 }}
      onChange={onChange}
      type="select"
    />
  );
};
