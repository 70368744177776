// @ts-nocheck
import { Text, VStack } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { UmotionCowGroupSelector } from '~/components/UmotionCowGroupSelector';
import { UmotionPenSelector } from '~/components/UmotionPenSelector';
import { usePostCowMutation } from '~/packages/u-motion-api/api/cowAutoGeneratedApi';

const fields = [
  {
    key: 'cowUid',
    label: '個体識別番号',
    rules: {
      required: {
        value: true,
        message: '個体識別番号を入力してください',
      },
    },
  },
  {
    key: 'cowName',
    label: '名前',
  },
  {
    key: 'cowNo',
    label: '牛番号',
    rules: {
      required: {
        value: true,
        message: '牛番号を入力してください',
      },
    },
  },
  {
    key: 'registrationNo',
    label: '登記番号',
  },
  {
    key: 'sensor_id',
    label: 'センサー番号',
  },
  {
    key: 'birthday',
    label: '出生日',
    type: 'datePicker',
    rules: {
      required: {
        value: true,
        message: '出生日を入力してください',
      },
    },
  },
  {
    key: 'introductionDate',
    label: '導入日',
    type: 'datePicker',
  },
  {
    key: 'gender',
    label: '性別',
    wrapperStyle: { zIndex: 20 },
    type: 'selectPopover',
    options: [
      { label: 'オス', value: 'male' },
      { label: 'メス', value: 'female' },
      { label: 'フリーマーチン', value: 'freemartin' },
      { label: '去勢', value: 'castrated' },
    ],
    rules: {
      required: {
        value: true,
        message: '性別を選択してください',
      },
    },
  },
  {
    key: 'fatherName',
    label: '父牛の名前',
  },
  {
    key: 'motherName',
    label: '母牛の名前',
  },
  {
    key: 'motherBirthNumber',
    label: '母牛の分娩回数',
    type: 'number',
  },
  {
    key: 'maternalGrandfatherName',
    label: '母父牛の名前',
  },
  {
    key: 'maternalGrandmotherName',
    label: '母母牛の名前',
  },
  {
    key: 'maternalGreatGrandfatherName',
    label: '母母父牛の名前',
  },
];

type CowData = {
  cowUid: string;
  cowNo: string;
  cowName: string;
  sensor_id: string;
  birthDate: string;
  introductionDate: string;
  gender: string;
  father: string;
  mother: string;
  maternalGrandfather: string;
  maternalGreatGrandfather: string;
  maternalGrandmother: string;
  maternalGrandmothersFather: string;
  maternalGreatGreatGrandfather: string;
  motherParityNumber: string;
  longestBreedingLocation: string;
  longestBreedingFarmerName: string;
  registrationNo: string;
  motherBirthNumber: string;
  pen?: string;
};

type Props = {
  cow: CowData;
  onCancel: () => void;
  onError: (error: string) => void;
  onSuccess: () => void;
};

export const RegisterToUmotion = ({ cow, onCancel, onError, onSuccess }: Props) => {
  const [postCow, postCowResult] = usePostCowMutation();
  const defaultValues = getDefaultValues(cow);
  const [cowGroupId, setCowGroupId] = useState<string | undefined>(undefined);
  const [pen, setPen] = useState<string | undefined>(undefined);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const onSubmit = useCallback(
    (cow: CowData) => {
      const result = postCow({
        cow: {
          cowGroupId,
          cowNo: cow.cowNo,
          cowName: cow.cowName,
          cowUid: cow.cowUid,
          introduceDate: convertToValueOf(cow.introductionDate),
          birthday: convertToValueOf(cow.birthday),
          gender: cow.gender,
          fatherName: cow.fatherName,
          motherName: cow.motherName,
          maternalGrandfatherName: cow.maternalGrandfatherName,
          maternalGreatGrandfatherName: cow.maternalGreatGrandfatherName,
          maternalGrandmotherName: cow.maternalGrandmotherName,
          // maternalGrandmothersFatherとmaternalGreatGreatGrandfatherに対応するフィールドがないため、省略しています
          motherBirthNumber: cow.motherParityNumber,
          producingArea: cow.producingArea,
          producingFarmName: cow.producingFarmName,
          // registrationScoreとregistrationNumberに対応するフィールドがないため、省略しています
          pen,
        },
      })
        .unwrap()
        .then((data) => {
          onSuccess?.(data?.cowId);
        })
        .catch((error) => {
          console.error('postCow error: ', error);
          onError?.(error);
        });
    },
    [cowGroupId, pen]
  );

  return (
    <BaseScreenBuilder>
      <VStack space="lg">
        <UmotionCowGroupSelector onChange={(cowGroupId) => setCowGroupId(cowGroupId)} label="登録先牛群" />
        <UmotionPenSelector onChange={(pen) => setPen(pen)} label="牛房" cowGroupId={cowGroupId} />
        <BaseFormBuilder
          // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
          queryResult={{}}
          onSubmit={onSubmit}
          onCancel={onCancel}
          defaultValues={defaultValues}
          fields={fields}
        />
        {errorMessages
          ? errorMessages.map((errorMessage, i) => (
              <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
                {errorMessage}
              </Text>
            ))
          : null}
      </VStack>
    </BaseScreenBuilder>
  );
};

const getDefaultValues = (cow: CowData) => {
  return {
    cowUid: cow?.cowUid || '', //個体識別番号
    cowName: cow?.cowName || '', //名前
    cowNo: cow?.cowNo || '', //牛番号
    registrationNo: cow?.registrationNo || '', //登録番号
    sensor_id: cow?.sensor_id || '',
    birthday: cow?.birthday || '', //出生日
    introductionDate: cow?.introduceDate || '', //導入日
    gender: convertGender(cow?.gender) || '', //性別
    fatherName: cow?.fatherName || '', //父牛の名前
    motherName: cow?.motherName || '', //母牛の名前
    maternalGrandfatherName: cow?.maternalGrandfatherName || '', //母父牛の名前
    maternalGrandmotherName: cow?.maternalGrandmotherName || '', //母母牛の名前

    maternalGreatGrandfatherName: cow?.maternalGreatGrandfatherName || '', // 母母父牛の名前
    motherBirthNumber: cow?.motherBirthNumber || '',
  };
};

const convertToValueOf = (dateString: string | undefined) => {
  if (!dateString) return '';
  return new Date(dateString).valueOf().toString();
};

const convertGender = (gender: string | undefined) => {
  if (!gender) return undefined;
  switch (gender) {
    case '雄':
      return 'male';
    case '雌':
      return 'female';
    case 'フリーマーチン':
      return 'freemartin';
    case '去勢':
      return 'castrated';
    default:
      return undefined;
  }
};
