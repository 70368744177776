import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type Selected = { type: 'my' | 'dm'; channelId?: string; messageId?: string };
export type ChatSearchResultItemState = {
  selected?: Selected;
};

const slice = createSlice({
  name: 'chatSearchResultItem',
  initialState: { selected: undefined } as ChatSearchResultItemState,
  reducers: {
    setSelected: (state, { payload }: PayloadAction<Selected>) => {
      state.selected = payload;
    },
  },
});

export const { setSelected } = slice.actions;

export const chatSearchResultItemSelector = (state: RootState) => {
  return state.chatSearchResultItem;
};

export default slice.reducer;
