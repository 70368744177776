import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type SettingsState = {
  enableNetworkLog?: boolean;
  enableKanasapo?: boolean;
  isUseAppointmentService?: boolean;
  isAppointmentServiceProvider?: boolean;
  enableWebNotification?: boolean;
  enableUDFont?: boolean;
  enablePushNotification?: boolean;
  enableMarkdown?: boolean;
  enableMentionOnly?: boolean;
};

const slice = createSlice({
  name: 'settings',
  initialState: {
    enableNetworkLog: false,
    enableKanasapo: false,
    enableWebNotification: true,
    enableUDFont: false,
    enablePushNotification: false,
    enableMarkdown: false,
    enableMentionOnly: false,
  } as SettingsState,
  reducers: {
    setEnableNetworkLog: (state, { payload }: PayloadAction<boolean>) => {
      state.enableNetworkLog = payload;
    },
    setEnableKanasapo: (state, { payload }: PayloadAction<boolean>) => {
      state.enableKanasapo = payload;
    },
    setIsUseAppointmentService: (state, { payload }: PayloadAction<boolean>) => {
      state.isUseAppointmentService = payload;
    },
    setIsAppointmentServiceProvider: (state, { payload }: PayloadAction<boolean>) => {
      state.isAppointmentServiceProvider = payload;
    },
    setEnableWebNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.enableWebNotification = payload;
    },
    setEnableUDFont: (state, { payload }: PayloadAction<boolean>) => {
      state.enableUDFont = payload;
    },
    setEnablePushNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.enablePushNotification = payload;
    },
    setEnableMarkdown: (state, { payload }: PayloadAction<boolean>) => {
      state.enableMarkdown = payload;
    },
    setEnableMentionOnly: (state, { payload }: PayloadAction<boolean>) => {
      state.enableMentionOnly = payload;
    },
  },
});

export const {
  setEnableNetworkLog,
  setIsAppointmentServiceProvider,
  setIsUseAppointmentService,
  setEnableKanasapo,
  setEnableWebNotification,
  setEnableUDFont,
  setEnablePushNotification,
  setEnableMarkdown,
  setEnableMentionOnly,
} = slice.actions;

export const settingsSelector = (state: RootState) => {
  return state.settings;
};

export default slice.reducer;
