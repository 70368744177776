import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Box } from '@gluestack-ui/themed-native-base';
import { useCallback, memo } from 'react';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import {
  chatSearchResultItemSelector,
  setSelected,
  type ChatSearchResultItemState,
} from '~/slices/chatSearchResultItemSlice';
import { useAppDispatch, useAppSelector } from '~/store';
import { BookmarkList } from './BookmarkList';
import type { StreamMessage } from 'stream-chat-react';

const SideMenu: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const handleMessagePress = useCallback(
    (message?: StreamMessage) => {
      dispatch(
        setSelected({
          type: message?.channel?.isDM ? 'dm' : 'my',
          channelId: message?.channel?.id,
          messageId: message?.id,
        })
      );
    },
    [dispatch, setSelected]
  );

  return (
    <Box
      width={450}
      height="100%"
      borderColor="surfaceHighest"
      borderRightWidth={gluestackUIConfig.tokens.borderWidths.thick}
    >
      <BookmarkList handleMessagePress={handleMessagePress} />
    </Box>
  );
});

export const ChatBookmarkListScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedMessage: ChatSearchResultItemState = useAppSelector(chatSearchResultItemSelector);

  useDidMount(() => {
    dispatch(setSelected({ type: 'my' }));
  });

  return (
    <>
      {selectedMessage?.selected?.channelId ? (
        <ChatChannelListWeb
          type={selectedMessage?.selected?.type}
          channelId={selectedMessage?.selected?.channelId}
          messageId={selectedMessage?.selected?.messageId}
          sideMenu={<SideMenu />}
          hideChannelList
        />
      ) : (
        <SideMenu />
      )}
    </>
  );
};
